/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap');*/

@font-face {
    font-family: 'OpenSans';
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap')
    /*local('OpenSans'), url(assets/fonts/OpenSans.ttf) format('ttf');*/
}

@font-face {
    font-family: 'Futura';
    src: local('Futura'), url(assets/fonts/Futura.ttc) format('ttc');
}

@font-face {
    font-family: 'Montreal-Regular';
    src: local('Montreal-Regular'), url(assets/fonts/Montreal-Regular.otf) format('otf');
}

@font-face {
    font-family: 'Montreal-Light';
    src: local('Montreal-Light'), url(assets/fonts/Montreal-Light.otf) format('otf');
}

@font-face {
    font-family: 'Montreal-RegularIta';
    src: local('Montreal-RegularIta'), url(assets/fonts/Montreal-RegularIta.otf) format('otf');
}

@font-face {
    font-family: 'Montreal-Bold';
    src: local('Montreal-Bold'), url(assets/fonts/Montreal-Bold.otf) format('otf');
}

@font-face {
    font-family: 'Montreal-BoldIta';
    src: local('Montreal-BoldIta'), url(assets/fonts/Montreal-BoldIta.otf) format('otf');
}


@font-face {
    font-family: 'Montreal-HeavyIta';
    src: local('Montreal-HeavyIta'), url(assets/fonts/Montreal-HeavyIta.otf) format('otf');
}


@font-face {
    font-family: 'Montreal-Xlight';
    src: local('Montreal-Xlight'), url(assets/fonts/Montreal-Xlight.otf) format('otf');
}


@font-face {
    font-family: 'Montreal-DemiBold';
    src: local('Montreal-DemiBold'), url(assets/fonts/Montreal-DemiBold.otf) format('otf');
}


@font-face {
    font-family: 'Montreal-Medium';
    src: local('Montreal-Medium'), url(assets/fonts/Montreal-Medium.otf) format('otf');
}

@font-face {
    font-family: 'Montreal-Heavy';
    src: local('Montreal-Heavy'), url(assets/fonts/Montreal-Heavy.otf) format('otf');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'OpenSans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none !important;
    color: #303030FF;
}

.tawk-branding {
    display: none !important;
}

.sponsorship-hover:hover {
    background: yellow !important;
    /*transform: scale(1.01);*/
}